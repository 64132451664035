import React from 'react';
import { Avatar } from 'react-daisyui';

export type shape = 'circle' | 'square';
export type size = 'lg' | 'md' | 'sm' | 'xs';
export type color =
  | 'accent'
  | 'error'
  | 'info'
  | 'neutral'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning';

export interface IAppAvatarProps {
  border?: boolean;
  borderColor?: color;
  color?: color;
  imgUri?: string;
  letters?: string;
  shape?: shape;
  size?: size;
}

const AppShapeStyleMap: Record<shape, string> = {
  circle: 'text-xs font-semibold border bg-sr-white border-sr-white',
  square:
    'border-2 !rounded-2xl border-sr-black hover:border-2 hover:border-neutral',
};

const AppAvatar = ({
  border,
  borderColor,
  color,
  imgUri,
  letters,
  shape = 'circle',
  size,
}: IAppAvatarProps) => {
  const className = AppShapeStyleMap[shape];

  return (
    <Avatar
      border={border}
      borderColor={borderColor}
      color={color}
      innerClassName={className}
      letters={letters}
      shape={shape}
      size={size}
      src={imgUri}
    />
  );
};

export default AppAvatar;
