import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  CreateUserRoleDto,
  ErrorResponse,
  FindUserRolesDto,
  UpdateUserRoleDto,
  UserRolesApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance, {
  invalidateCacheRequestConfig,
} from '../../utils/axiosInstance';
import { UserRoleSliceState } from './userRoleSliceState';

// Function for creating an instance of the UserRolesApi class
const GetUserRolesApi = () => {
  return new UserRolesApi(
    new Configuration(),
    configuration.PERMISSIONS_API_BASE,
    axiosInstance,
  );
};

const create = createAsyncThunk(
  'user-role/create',
  async function (payload: CreateUserRoleDto, thunkApi) {
    try {
      const result = await GetUserRolesApi().userRolesControllerCreate(
        payload,
        invalidateCacheRequestConfig(`user?workspaceId=${payload.workspaceId}`),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findAll = createAsyncThunk(
  'user-role/findAll',
  async (
    { permissionKey, userId, workspaceId }: FindUserRolesDto,
    thunkApi,
  ) => {
    try {
      const result = await GetUserRolesApi().userRolesControllerFindAll(
        permissionKey,
        userId,
        workspaceId,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const remove = createAsyncThunk(
  'user-role/remove',
  async (id: string, thunkApi) => {
    try {
      const result = await GetUserRolesApi().userRolesControllerRemove(
        id,
        invalidateCacheRequestConfig('user?workspaceId'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const update = createAsyncThunk(
  'user-role/update',
  async (
    {
      id,
      updateUserRoleDto,
    }: { id: string; updateUserRoleDto: UpdateUserRoleDto },
    thunkApi,
  ) => {
    try {
      const result = await GetUserRolesApi().userRolesControllerUpdate(
        id,
        updateUserRoleDto,
        invalidateCacheRequestConfig('user/me'),
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialUserRoleState: UserRoleSliceState = {
  isCreating: ApiLoadingStateEnum.idle,
  isLoading: ApiLoadingStateEnum.idle,
  isRemoving: ApiLoadingStateEnum.idle,
  isUpdating: ApiLoadingStateEnum.idle,
  userRoles: [],
};

const userRoleSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // Create
      .addCase(create.fulfilled, (state) => {
        state.isCreating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(create.pending, (state) => {
        state.isCreating = ApiLoadingStateEnum.loading;
      })
      .addCase(create.rejected, (state, action) => {
        state.isCreating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.userRoles = action.payload;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Remove
      .addCase(remove.fulfilled, (state) => {
        state.isRemoving = ApiLoadingStateEnum.succeeded;
      })
      .addCase(remove.pending, (state) => {
        state.isRemoving = ApiLoadingStateEnum.loading;
      })
      .addCase(remove.rejected, (state, action) => {
        state.isRemoving = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });

    builder
      // Update
      .addCase(update.fulfilled, (state) => {
        state.isUpdating = ApiLoadingStateEnum.succeeded;
      })
      .addCase(update.pending, (state) => {
        state.isUpdating = ApiLoadingStateEnum.loading;
      })
      .addCase(update.rejected, (state, action) => {
        state.isUpdating = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialUserRoleState,
  name: 'userRoles',
  reducers: {
    resetLoadingState: (state) => {
      return {
        ...state,
        // Add more loading state here
        isCreating: ApiLoadingStateEnum.idle,
        isRemoving: ApiLoadingStateEnum.idle,
        isUpdating: ApiLoadingStateEnum.idle,
      };
    },
  },
});

const userRoleThunk = {
  create: create,
  findAll: findAll,
  remove: remove,
  update: update,
};

const userRoleSelectors = {
  isCreating: (state: RootState) => state.userRole.isCreating,
  isLoading: (state: RootState) => state.userRole.isLoading,
  isRemoving: (state: RootState) => state.userRole.isRemoving,
  isUpdating: (state: RootState) => state.userRole.isUpdating,
  userRoles: (state: RootState) => state.userRole.userRoles,
};

export const userRoleService = {
  ...userRoleThunk,
  actions: userRoleSlice.actions,
  selectors: userRoleSelectors,
};

export default userRoleSlice.reducer;
