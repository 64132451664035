import React from 'react';
import { reduxServices } from '../reducers/reduxServices';
import { useTypedSelector } from '../stateStore';

export interface IIfFeatureEnabledProps extends React.PropsWithChildren {
  isEnabled?: string;
  name: string;
}

/**
 * Wrap code blocks in IfFeatureEnabled if they should only be conditionally rendered based on the feature flag given in
 * the 'name' prop.
 *
 * The default is to render children when the value of the feature flag is 'true'.
 *
 * Pass isEnabled="false" to instead render when the value of the feature flag is 'false'. This is useful for turning
 * off some code at the same time when the new feature is enabled.
 */
export const IfFeatureEnabled = ({
  children,
  isEnabled = 'true',
  name,
}: IIfFeatureEnabledProps) => {
  const featuresEnabled = useTypedSelector(
    reduxServices.setting.selectors.features,
  );

  return featuresEnabled?.[name] === isEnabled ? <>{children}</> : <></>;
};

export default IfFeatureEnabled;
