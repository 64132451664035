import { RoleEnum } from '@sr-sdks/api-sdk-axios';

export const ALL_WORKSPACES = '*';

export const AccessLevelMap: Record<RoleEnum, string> = {
  'authenticated-user': 'Authenticated User',
  'customer-support-user': 'Customer Support',
  'payment-authoriser': 'Payment Authoriser',
  'payment-preparer': 'Payment Preparer',
  'system-admin': 'System Admin',
  'system-process': 'System Process',
  'workspace-admin': 'Admin',
  'workspace-owner': 'Owner',
  'workspace-user': 'Standard',
};

export const PAYOUT_STATUS = 'status';

export const PAYOUT_SEARCH = 'searchValue';
