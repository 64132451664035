/* eslint-disable perfectionist/sort-objects */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  ActivitiesApi,
  Activity,
  Configuration,
  ErrorResponse,
  PaginatedActivitiesDto,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { ActivitySliceState } from './activitySliceState';

const GetActivitiesApi = () => {
  return new ActivitiesApi(
    new Configuration(),
    configuration.SHARED_API_BASE,
    axiosInstance as any,
  );
};

const findAllPaginatedActivities = createAsyncThunk(
  'activity/findAllPaginated',
  async (
    paginationParams: {
      name?: string;
      page?: number;
      pageSize?: number;
      sortBy?: string;
      workspaceId?: string;
    },
    thunkApi,
  ) => {
    try {
      const { name, workspaceId, page, pageSize, sortBy } = paginationParams;

      const result = await GetActivitiesApi().activityControllerFindAll(
        name,
        workspaceId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const findActivityById = createAsyncThunk(
  'activity/findById',
  async (id: string, thunkApi) => {
    try {
      const result = await GetActivitiesApi().activityControllerFindOne(id);

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialActivityState: ActivitySliceState = {
  activities: [],
  activity: undefined,
  errorResponse: undefined,
  isLoading: ApiLoadingStateEnum.idle,
  paginationMeta: undefined,
};

const activitySlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addCase(findAllPaginatedActivities.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAllPaginatedActivities.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        const paginatedResponse = action.payload as PaginatedActivitiesDto;

        state.activities = paginatedResponse.items;
        state.paginationMeta = {
          ...action.payload.meta,
          currentPage: Number(action.payload.meta.currentPage),
          itemsPerPage: Number(action.payload.meta.itemsPerPage),
          totalPages: Number(action.payload.meta.totalPages),
        };
      })
      .addCase(findAllPaginatedActivities.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      })

      // Handle fetching individual activity
      .addCase(findActivityById.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findActivityById.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;
        state.activity = action.payload as Activity;
      })
      .addCase(findActivityById.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialActivityState,
  name: 'activity',
  reducers: {},
});

const activityThunk = {
  findActivityById,
  findAllPaginatedActivities,
};

const activitySelectors = {
  activities: (state: RootState) => state.activity.activities,
  activity: (state: RootState) => state.activity.activity,
  errorResponse: (state: RootState) => state.activity.errorResponse,
  isLoading: (state: RootState) => state.activity.isLoading,
  paginationMeta: (state: RootState) => state.activity.paginationMeta,
};

export const activityService = {
  ...activityThunk,
  actions: activitySlice.actions,
  selectors: activitySelectors,
};

export default activitySlice.reducer;
