import {
  IconDefinition,
  faCircleDollarToSlot,
  faCrown,
  faMoneyBillTransfer,
  faMoneyCheckDollar,
} from '@fortawesome/free-solid-svg-icons';
import {
  AgreementContractStatusEnum,
  EntityTypeEnum,
  RoleEnum,
  SaleStatusEnum,
  ServiceTypeEnum,
  User,
  UserRole,
  Workspace,
  WorkspaceStatusEnum,
  WorkspaceTypeEnum,
} from '@sr-sdks/api-sdk-axios';
import { AppBadgeColor, AppBadgeIcon } from '../../components/AppBadge';
import { ActivityStatementEnum } from '../../types/workspaceTypes';
import { ApiLoadingStateEnum } from '../api/apiLoadingStateEnum';

export const getBusinessNumberLabel = (businessNumberCountry?: string) => {
  if (businessNumberCountry && businessNumberLabels[businessNumberCountry])
    return businessNumberLabels[businessNumberCountry];

  return 'Business Number';
};

export const businessNumberLabels: Record<string, string> = {
  AU: 'ABN',
  CH: 'UID',
  FR: 'SIREN/SIRET',
  GB: 'CRN',
  NZ: 'NZBN',
  US: 'EIN',
};

export const workspaceTypeIsAgent = (workspace?: Workspace) => {
  switch (workspace?.workspaceType) {
    case WorkspaceTypeEnum.BusinessAgency:
      return true;

    default:
      return false;
  }
};

export const workspaceTypeIsBusiness = (workspace?: Workspace) => {
  switch (workspace?.workspaceType) {
    case WorkspaceTypeEnum.BusinessAgency:
    case WorkspaceTypeEnum.BusinessNonAgency:
      return true;

    default:
      return false;
  }
};

export const workspaceTypeIsIndividual = (workspace?: Workspace) => {
  switch (workspace?.workspaceType) {
    case WorkspaceTypeEnum.Individual:
      return true;

    default:
      return false;
  }
};

export const workspaceTypeFromEntityType = (
  entityType: EntityTypeEnum,
  representsClients: string,
) => {
  // Determine workspaceType based on entityType and representsClients
  return entityType === EntityTypeEnum.Individual
    ? WorkspaceTypeEnum.Individual
    : representsClients === 'yes'
    ? WorkspaceTypeEnum.BusinessAgency
    : WorkspaceTypeEnum.BusinessNonAgency;
};

export const isLoadingState = (value: string): boolean => {
  return value === ApiLoadingStateEnum.loading;
};

export const isFailedState = (value: string): boolean => {
  return value === ApiLoadingStateEnum.failed;
};

export const isSuccessState = (value: string): boolean => {
  return value === ApiLoadingStateEnum.succeeded;
};

// Fastest algorithm
export const removeSpace = (value: string): string => value.replace(/\s+/g, '');

export const isValidPattern = (pattern: string, value: string): boolean => {
  const regex = new RegExp(pattern, 'i');

  return regex.test(value);
};

export const numberFormat = (number: string): string => {
  return number.replace(/\D/g, '');
};

// Camel Case conversion
// Source: javascript.plainenglish.io
// From: John Au-Yeung
// URL: https://javascript.plainenglish.io/how-to-convert-any-string-into-camel-case-with-javascript-1ec0a53ae798
export const toCamelCase = (str: string): string => {
  return (
    str
      .toLowerCase()
      // Replace non alpha-numeric with space
      .replace(/[^a-z0-9 ]/g, ' ')
      // Uppercase letters preceded by a space or number
      .replace(/^\w|[0-9]|\b\w/g, (word: string, index: number) => {
        return index === 0 ? word.toLowerCase() : word.toUpperCase();
      })
      // Remove spaces
      .replace(/\s+/g, '')
  );
};

export const capitalizeWords = (value: string): string => {
  const stringArray = value.split(' ');

  for (let i = 0; i < stringArray.length; i++) {
    stringArray[i] =
      stringArray[i].charAt(0).toUpperCase() + stringArray[i].slice(1);
  }

  return stringArray.join(' ');
};

export const shouldVerifyBusinessNumber = (businessNumberCountry?: string) => {
  switch (businessNumberCountry) {
    case 'AU':
      return true;

    default:
      return false;
  }
};

export const userIsWorkspaceOwner = (user: User) =>
  user.roles.some((value) => value.role.key === RoleEnum.WorkspaceOwner);

export const userIsWorkspaceAdmin = (user: User) =>
  user.roles.some((value) => value.role.key === RoleEnum.WorkspaceAdmin);

export const userIsSystemProcess = (user: User) =>
  user.roles.some((value) => value.role.key === RoleEnum.SystemProcess);

export const userIsMe = (user: User, currentUser?: User) =>
  user.id === currentUser?.id;

export const userInitials = (userName: string) => {
  return userName
    ?.replace(/[^a-z0-9 ]/gi, '')
    .split(' ')
    .map((w) => w.charAt(0).toUpperCase())
    .slice(0, 3)
    .join('');
};

export const staffUserHighestRole = (
  roles: UserRole[],
): UserRole | undefined => {
  const systemAdmin = roles.find(
    (value) => value.role.key === RoleEnum.SystemAdmin,
  );

  if (systemAdmin) return systemAdmin;

  const paymentAuthoriser = roles.find(
    (value) => value.role.key === RoleEnum.PaymentAuthoriser,
  );

  if (paymentAuthoriser) return paymentAuthoriser;

  const paymentPreparer = roles.find(
    (value) => value.role.key === RoleEnum.PaymentPreparer,
  );

  if (paymentPreparer) return paymentPreparer;

  const customerSupportUser = roles.find(
    (value) => value.role.key === RoleEnum.CustomerSupportUser,
  );

  if (customerSupportUser) return customerSupportUser;

  const systemProcess = roles.find(
    (value) => value.role.key === RoleEnum.SystemProcess,
  );

  if (systemProcess) return systemProcess;
};

export const userHighestRole = (roles: UserRole[]): UserRole | undefined => {
  const owner = roles.find(
    (value) => value.role.key === RoleEnum.WorkspaceOwner,
  );

  if (owner != null) return owner;

  const admin = roles.find(
    (value) => value.role.key === RoleEnum.WorkspaceAdmin,
  );

  if (admin != null) return admin;

  const standard = roles.find(
    (value) => value.role.key === RoleEnum.WorkspaceUser,
  );

  if (standard != null) return standard;
};

export const pluralize = (
  count: number,
  singularText: string,
  pluralText: string,
) => {
  if (count == 1) {
    return `${count} ${singularText}`;
  }

  return `${count} ${pluralText}`;
};

export function uniqueId() {
  const base64chars =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';
  // Tslint:disable-next-line:insecure-random
  let random = Math.random() * 1073741824; // 5 symbols in base64, almost maxint

  while (random > 0) {
    const char = base64chars.charAt(random % 64);

    result += char;
    random = Math.floor(random / 64);
  }

  return result;
}

export const workspaceStatusBadgeColor = (key: WorkspaceStatusEnum) => {
  const statusMap: Record<WorkspaceStatusEnum, AppBadgeColor> = {
    active: 'success',
    disabled: 'default',
    invited: 'primary',
    onHold: 'default',
    readyToOnboard: 'dark-accent',
  };

  return statusMap[key] || 'default';
};

export const agreementStatusBadgeColor = (key: AgreementContractStatusEnum) => {
  const statusMap: Record<AgreementContractStatusEnum, AppBadgeColor> = {
    active: 'success',
    archive: 'default',
    expired: 'warning',
    terminated: 'error',
  };

  return statusMap[key] || 'default';
};

export const saleStatusBadgeColor = (key: SaleStatusEnum) => {
  const statusMap: Record<SaleStatusEnum, AppBadgeColor> = {
    collected: 'success',
    disbursed: 'primary',
    pending: 'default',
  };

  return statusMap[key] || 'default';
};

// TODO: remove ActivityStatusType when SR SDK is available
type ActivityStatusType = keyof typeof ActivityStatementEnum;

export const activityStatusBadgeColor = (key: ActivityStatusType) => {
  const statusMap: Record<ActivityStatusType, AppBadgeColor> = {
    Final: 'success',
    Provisional: 'warning',
  };

  return statusMap[key] || 'default';
};

export const workspaceStatusBadgeIcon = (key: WorkspaceStatusEnum) => {
  const statusMap: Record<string, AppBadgeIcon> = {
    active: 'success',
    failed: 'error',
    invited: 'success',
  };

  return statusMap[key] || 'default';
};

export type ThemeColor =
  | 'accent'
  | 'error'
  | 'ghost'
  | 'info'
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | undefined;

export const toTitleCase = (text: string) => {
  if (!text) return '';

  const result = text.replace(/([A-Z])/g, ' $1');

  return result.charAt(0).toUpperCase() + result.slice(1);
};

export const isNumber = (number: string) => /\d/.test(number);

type BadgeValueProps = {
  color: 'accent' | 'primary' | 'success' | 'warning';
  icon: IconDefinition;
  label: string;
};

export const SRBadgeProps: Record<'*' | ServiceTypeEnum, BadgeValueProps> = {
  '*': {
    color: 'primary',
    icon: faMoneyCheckDollar,
    label: 'ALL SERVICES',
  },
  collectionAccount: {
    color: 'success',
    icon: faCircleDollarToSlot,
    label: 'CAM',
  },
  disbursements: {
    color: 'warning',
    icon: faMoneyBillTransfer,
    label: 'DAS',
  },
  residuals: {
    color: 'primary',
    icon: faMoneyCheckDollar,
    label: 'Residuals',
  },
  /* No Icons and Colors Provided for the ff. */
  royaltiesWorld: {
    color: 'accent',
    icon: faCrown,
    label: 'Royalties',
  },
  secondary: {
    color: 'primary',
    icon: faMoneyCheckDollar,
    label: 'Secondary',
  },
};

export const toMaxDateConverter = (date: string): string => {
  // Parse the original date string
  const originalDate = new Date(date);

  // Set the time to end of the day
  originalDate.setHours(23, 59, 59, 999);

  // Convert to UTC and format the end of day as an ISO string
  return originalDate.toISOString();
};

export const toMinDateConverter = (dateString: string): string => {
  const date = new Date(dateString);

  date.setHours(0, 0, 0, 0);

  return date.toISOString();
};

export const isDefinedAndNotEmpty = (value?: string) => {
  return value !== undefined && value !== null && value !== '';
};
