import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  Configuration,
  ErrorResponse,
  InvoiceStatusEnum,
  InvoicesApi,
} from '@sr-sdks/api-sdk-axios';
import { AxiosError } from 'axios';
import { configuration } from '../../configuration';
import { RootState } from '../../stateStore';
import { ApiLoadingStateEnum } from '../../utils/api/apiLoadingStateEnum';
import axiosInstance from '../../utils/axiosInstance';
import { InvoiceSliceState, PaginatedInvoicesDto } from './invoiceSliceState';

// Function for creating an instance of the UsersApi class
const GetInvoicesApi = () => {
  return new InvoicesApi(
    new Configuration(),
    configuration.PAYMENTS_API_BASE,
    axiosInstance,
  );
};

const findAll = createAsyncThunk(
  'invoice/findAll',
  async (
    {
      beneficiaryTitleId,
      beneficiaryWorkspaceId,
      ownerWorkspaceId,
      page,
      pageSize,
      payoutId,
      sortBy,
      status,
    }: {
      beneficiaryTitleId?: string;
      beneficiaryWorkspaceId?: string;
      ownerWorkspaceId?: string;
      page?: number;
      pageSize?: number;
      payoutId?: string;
      sortBy?: string;
      status?: InvoiceStatusEnum;
    },
    thunkApi,
  ) => {
    try {
      const result = await GetInvoicesApi().invoiceControllerFindAll(
        beneficiaryTitleId,
        status,
        beneficiaryWorkspaceId,
        ownerWorkspaceId,
        payoutId,
        page,
        pageSize,
        sortBy,
      );

      return result.data;
    } catch (error) {
      return thunkApi.rejectWithValue(
        (error as AxiosError).response?.data as ErrorResponse,
      );
    }
  },
);

const initialInvoiceState: InvoiceSliceState = {
  invoices: [],
  isLoading: ApiLoadingStateEnum.idle,
};

const invoicesSlice = createSlice({
  extraReducers: (builder) => {
    builder
      // FindAll
      .addCase(findAll.fulfilled, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.succeeded;

        const paginatedResponse = action.payload as any as PaginatedInvoicesDto;

        state.invoices = paginatedResponse.items;
        state.paginationMeta = paginatedResponse.meta;
      })
      .addCase(findAll.pending, (state) => {
        state.isLoading = ApiLoadingStateEnum.loading;
      })
      .addCase(findAll.rejected, (state, action) => {
        state.isLoading = ApiLoadingStateEnum.failed;
        state.errorResponse = action.payload as ErrorResponse;
      });
  },
  initialState: initialInvoiceState,
  name: 'invoice',
  reducers: {
    resetErrorResponse: (state) => {
      return {
        ...state,
        errorResponse: initialInvoiceState.errorResponse,
      };
    },
    setDownloadErrorResponse: (state, action) => {
      return {
        ...state,
        errorResponse: action.payload,
      };
    },
  },
});

const invoiceThunk = {
  findAll,
};

const invoiceSelectors = {
  errorResponse: (state: RootState) => state.invoices.errorResponse,
  invoices: (state: RootState) => state.invoices.invoices,
  isLoading: (state: RootState) => state.invoices.isLoading,
  paginationMeta: (state: RootState) => state.invoices.paginationMeta,
};

export const invoiceService = {
  ...invoiceThunk,
  actions: invoicesSlice.actions,
  selectors: invoiceSelectors,
};

export default invoicesSlice.reducer;
