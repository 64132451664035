import { OnboardingStatusEnum, RoleEnum } from '@sr-sdks/api-sdk-axios';
import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { userService } from '../reducers/permissions/userSlice';
import { useTypedSelector } from '../stateStore';

interface WorkspaceRedirectProps {
  children: React.ReactNode;
}

export const WorkspaceRedirect: React.FC<WorkspaceRedirectProps> = ({
  children,
}) => {
  const { workspaceId } = useParams();
  const navigate = useNavigate();
  const userMe = useTypedSelector(userService.selectors.userMe);

  useEffect(() => {
    if (userMe && workspaceId) {
      // We only check the workspace owner role because setting up the workspace
      // is mandatory for owners. Other roles (admin, user) don't need to go through
      // this specific onboarding process.
      const workspaceOwnerRole = userMe.roles.find(
        (role) =>
          role.workspaceId === workspaceId &&
          role.role.key === RoleEnum.WorkspaceOwner,
      );

      if (
        workspaceOwnerRole &&
        workspaceOwnerRole.onboardingStatus !== OnboardingStatusEnum.Completed
      ) {
        navigate(`/onboarding/${workspaceId}/setup-workspace`);
      }
    }
  }, [userMe, workspaceId, navigate]);

  return <>{children}</>;
};
